.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #703BE7;
  color: white;
}

.company-menu-container {
  display: flex;
  align-items: center;
}

.company-name {
  margin: 8px;
  width: 250px;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 5px 0;
}

nav {
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 20px;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
}

header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1440px) {
  .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #703BE7;
  color: white;
}

.company-menu-container {
  display: flex;
  align-items: center;
}

.company-name {
  margin: 8px;
  width: 250px;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  margin: 5px 0;
}

nav {
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 20px;
  }

  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
}

header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
}

@media (max-width: 412px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .company-name {
    width: auto;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-left: 70px;
  }

  nav {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #1d247eef;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    z-index: 1000;
  }

  nav.show {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
  }

  li {
    margin-right: 15px;
  }

  header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(240, 223, 223, 0.1);
  }

  nav ul {
    padding: 0;
  }

  nav ul li {
    margin-bottom: 8px;
  }
}

@media (max-width: 378px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .company-name {
    width: auto;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-left: 20px;
  }

  nav {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #1d247eef;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    z-index: 1000;
  }

  nav.show {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
  }

  li {
    margin-right: 15px;
  }

  header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(240, 223, 223, 0.1);
  }

  nav ul {
    padding: 0;
  }

  nav ul li {
    margin-bottom: 8px;
  }
}